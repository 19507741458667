import React from "react"
import {graphql, Link} from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {buildLinkForPost, formatGMTDate, replaceLinkInExcerpt} from "../utils/utils";

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="Toutes les pages" />
        <p>Le site est vide pour l'instant.</p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="Toutes les pages" />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.title
          return (
            <li key={post.uri}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <p className="qq-date">Emission TPMP du {formatGMTDate(post.dateGmt)}</p>
                  <h2>
                    <Link to={buildLinkForPost(post)} itemProp="url">
                      <span itemProp="headline">{parse(title)}</span>
                    </Link>
                  </h2>
                </header>
                <section itemProp="description">{replaceLinkInExcerpt(post)}</section>
              </article>
            </li>
          )
        })}
      </ol>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        dateGmt
        title
        excerpt,
        id,
        slug,
        databaseId
      }
    }
  }
`
